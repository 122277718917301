import React from 'react';
import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/androidcentral.css';

loadCSS('androidcentral-site');

export const AndroidcentralSiteWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <>{children}</>;
};
export default AndroidcentralSiteWrapper;
